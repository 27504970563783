// src/components/Clients.jsx
import React from "react";

export const Clients = ({ data, subtitle }) => {
  return (
    <section id="clients">
      <div className="container">
        <div className="section-title">
          <h2>Our Clients</h2>
          {subtitle && <p>{subtitle}</p>}
        </div>
        <div className="clients-wrapper">
          {data && data.length > 0 ? (
            data.map((client, index) => (
              <div key={`${client.name}-${index}`} className="client-item">
                <h3>{client.name}</h3>
                {Array.isArray(client.text) ? (
                  <ul>
                    {client.text.map((item, idx) => (
                      <li key={idx}>{item}</li>
                    ))}
                  </ul>
                ) : (
                  <p>{client.text}</p>
                )}
              </div>
            ))
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
    </section>
  );
};
