// src\components\contact.jsx
import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const initialState = {
  name: "",
  email: "",
  company: "",  // Added company to initial state
  message: "",
};

export const Contact = (props) => {
  const [{ name, email, company, message }, setState] = useState(initialState); // Destructure company
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [errors, setErrors] = useState({});

  // Regular expressions for validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const nameRegex = /^[a-zA-Z\s]+$/;
  const companyRegex = /^[a-zA-Z0-9\s]+$/;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
    

    if (name === "email" && !emailRegex.test(value)) {
      setErrors((prevState) => ({ ...prevState, email: "Invalid email format." }));
    } else if (name === "name" && !nameRegex.test(value)) {
      setErrors((prevState) => ({ ...prevState, name: "Name can only contain letters and spaces." }));
    } else if (name === "company" && !companyRegex.test(value)) {
      setErrors((prevState) => ({ ...prevState, company: "Invalid company name." }));
    } else {
      setErrors((prevState) => ({ ...prevState, [name]: "" }));
    }
  };

  const clearState = () => setState({ ...initialState });

  const handleSubmit = async (e) => {
    e.preventDefault();

    // if (!recaptchaValue) {
    //   alert("Please complete the reCAPTCHA.");
    //   return;
    // }

    if (!emailRegex.test(email)) {
      setErrors({ email: "Please enter a valid email." });
      return;
    }
    if (!nameRegex.test(name)) {
      setErrors({ name: "Please enter a valid name." });
      return;
    }
    if (!companyRegex.test(company)) {
      setErrors({ company: "Please enter a valid company name." });
      return;
    }

    const formData = { name, email, company, message };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/send-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
    
      if (response.ok) {
        console.log("Email sent successfully");
        clearState(); // Reset form fields after successful submission
      } else {
        const errorData = await response.json();
        console.error("Failed to send email:", errorData);
      }
    } catch (error) {
      console.error("Error occurred during fetch:", error);
    }    
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>SCHEDULE A DEMO</h2>
                <p>
                  Please fill out the form below to schedule a demo and we will
                  get back to you as soon as possible.
                </p>
              </div>
              <form name="sentMessage" validate onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="form-control"
                        placeholder="Name"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger">{errors.name}</p> {/* Display error for name */}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                      />
                      <p className="help-block text-danger">{errors.email}</p> {/* Display error for email */}
                    </div>
                  </div>
                </div>
                {/* New Company input field */}
                <div className="form-group">
                  <input
                    type="text"
                    id="company"
                    name="company"
                    className="form-control"
                    placeholder="Company"
                    required
                    onChange={handleChange}
                  />
                  <p className="help-block text-danger">{errors.company}</p>
                  </div>
                <div className="form-group">
                  <textarea
                    name="message"
                    id="message"
                    className="form-control"
                    rows="4"
                    placeholder="Message"
                    required
                    onChange={handleChange}
                  ></textarea>
                </div>

                {/* <div className="form-group">
                  <ReCAPTCHA
                    sitekey="6LdUzloqAAAAAE626Vp6RggJ7xxZc2lnyGIkxM2U" // Replace with your site key from Google reCAPTCHA
                    onChange={(value) => setRecaptchaValue(value)}
                  />
                </div> */}

                <button type="submit" className="btn btn-custom btn-lg">
                  Send Message
                </button>
              </form>
            </div>
          </div>
          {/* Other unchanged parts of the component */}
          {/* Contact Info Section */}
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            {/* <div className="contact-item"> */}
              {/* <p> */}
                {/* <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "} */}
                {/* {props.data ? props.data.phone : "loading"} */}
              {/* </p> */}
            {/* </div> */}
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          {/* Social Links Section */}
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.linkedin : "/"}>
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2024 Credilyst AI™. All Rights Reserved.
          </p>
        </div>
      </div>
    </div>
  );
};
