// src/components/Features.jsx
import React from "react";

export const Features = (props) => {
  const features = props.data || [];

  // Split the first four features and the fifth feature
  const firstFourFeatures = features.slice(0, 4);
  const fifthFeature = features[4];

  return (
    <div id="features" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Features</h2>
        </div>

        {/* First Row: First Four Features */}
        <div className="features-row first-row">
          {firstFourFeatures.map((feature, index) => (
            <div key={`${feature.title}-${index}`} className="feature-item">
              <i className={feature.icon}></i>
              <h3>{feature.title}</h3>
              <p>{feature.text}</p>
            </div>
          ))}
        </div>

        {/* Second Row: 5th Feature Centered */}
        {fifthFeature && (
          <div className="features-row second-row">
            <div className="feature-item centered-feature">
              <i className={fifthFeature.icon}></i>
              <h3>{fifthFeature.title}</h3>
              <p>{fifthFeature.text}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
