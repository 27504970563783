// src/components/faqs.jsx
import React, { useState, useEffect } from "react";

export const FAQs = ({ data }) => {  // Accept data as a prop
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    console.log("Updated openIndex:", openIndex);
  }, [openIndex]);

  return (
    <div id="faqs" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Need Answers? We’ve Got You Covered.</h2>
          <p>
            If you have more queries, feel free to <a href="#contact">Contact Us</a>.
          </p>
        </div>
        <div className="faqs">
          {data ? (
            data.map((faq, index) => (
              <div key={index} className={`faq-block ${openIndex === index ? "open" : ""}`}>
                <div
                  className="faq-question-container"
                  onClick={() => toggleFAQ(index)}
                  role="button"
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.key === "Enter" || e.key === " ") {
                      toggleFAQ(index);
                    }
                  }}
                >
                  <h3 className="faq-question">{faq.question}</h3>
                  <span className="faq-toggle-icon"></span>
                </div>
                <p
                  className="faq-answer"
                  style={{
                    display: openIndex === index ? "block" : "none",
                  }}
                >
                  {faq.answer}
                </p>
              </div>
            ))
          ) : (
            "Loading FAQs..."
          )}
        </div>
      </div>
    </div>
  );
};
